import { Lockup, Text } from '@butcherbox/freezer'
import { Box, Flex, VisuallyHidden } from '@chakra-ui/core'
import { RouteComponentProps } from '@reach/router'
import { rem, styled } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import {
  BodySmaller,
  LinkInternal,
} from 'design/components/Typography/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { GroupedBoxItem } from '~/bb-api/schemata'
import AddonCard from '~/components/AddonCard/AddonCard'
import * as Styles from '~/components/AddonCard/AddonCard.css'
import { TEST_ID } from '~/constants/cypress'
import useAddons from '~/hooks/useAddons'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'

const AccountAddons: React.FC<RouteComponentProps> = () => {
  const { data: addons = [], isLoading: addonsLoading } = useAddons()
  const {
    data: upcomingOrder,
    isLoading: upcomingOrderLoading,
    status,
  } = useUpcomingOrder()

  // Create a map for faster lookup of quantity in individual deal cards
  const addonsMap = React.useMemo(
    () =>
      upcomingOrder?.subscription.box.addons.reduce<
        Record<GroupedBoxItem['sku'], GroupedBoxItem>
      >((obj, addon) => {
        obj[addon.sku] = addon
        return obj
      }, {}) || {},
    [upcomingOrder?.subscription.box.addons]
  )

  const loading = addonsLoading || upcomingOrderLoading
  const [isIngredientEnabled] = useOptimizelyFeature('shop-426_ingredient_link')

  return (
    <>
      <AddonsHero />

      <VisuallyHidden>
        <p>
          Add-ons are billed and shipped with every box until you remove them
        </p>
        <h2>Products</h2>
      </VisuallyHidden>

      <Box bg="bb.ivory">
        <Box px="0" py={{ base: rem(48), desktop: rem(80) }}>
          {status === 'error' &&
            'Sorry, something went wrong retrieving addons.'}

          {loading ? (
            <LoadingSpinner size="regular" />
          ) : (
            <Box
              as="ul"
              className={Styles.AddonGrid}
              data-cy={TEST_ID.ACCOUNT_ADDONS}
            >
              {addons.map((addon) => (
                <AddonCard
                  addon={addon}
                  addonsMap={addonsMap}
                  displayViewIngredientsLink={isIngredientEnabled}
                  key={addon.sku}
                />
              ))}
            </Box>
          )}

          <BodySmaller
            mt={{ base: rem(16), desktop: rem(24) }}
            textAlign="center"
          >
            Add-ons are recurring. They will be included in your next box and
            every box after that until you make a change. To view or change your
            next bill date, visit your{' '}
            <LinkInternal href="/account/box-settings">
              box settings page
            </LinkInternal>
            .
          </BodySmaller>
        </Box>
      </Box>
    </>
  )
}

const HERO_HEIGHT = 252

function AddonsHero() {
  const imageData = useStaticQuery(graphql`
    query {
      bg: file(
        relativePath: { eq: "top_down_cast_iron_meal_with_chicken.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <HeroImage fluid={imageData.bg.childImageSharp.fluid}>
      <Flex
        alignItems="center"
        backgroundColor="scrim.50pct"
        color="#fff"
        flexDir="column"
        height={rem(HERO_HEIGHT)}
        justifyContent="center"
        textAlign="center"
      >
        <Lockup>
          <Text color="white" component="h1" variant="DisplayOne">
            Essential Add-Ons
          </Text>
          <Text color="white" component="h2" variant="Subhead1">
            Ships in every box
          </Text>
        </Lockup>
      </Flex>
    </HeroImage>
  )
}

const HeroImage = styled(BackgroundImage)`
  height: ${rem(HERO_HEIGHT)};
  width: 100%;
`

export default AccountAddons
